import React from "react";
import { Container, Section, Wrapper } from "components/style";
import { Link, graphql, useStaticQuery } from "gatsby";
import ArtDirectionLogo from "assets/svgs/art-direction/art-direction_logo.svg";
import useAdaptive from "hooks/useAdaptive";
import * as SC from "./style";

const ArtDirectionForWhom = () => {
  const { isMobile } = useAdaptive();
  const data = useStaticQuery(graphql`
    query artDirectionForWhom {
      foundationForWhomImage3: file(
        relativePath: { eq: "foundation-of-graph-design/marketers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage4: file(
        relativePath: { eq: "foundation-of-graph-design/graphic-designers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage2: file(
        relativePath: { eq: "foundation-of-graph-design/supervisors.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage1: file(
        relativePath: { eq: "foundation-of-graph-design/newbees.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage5: file(
        relativePath: {
          eq: "foundation-of-graph-design/identity-designers.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foundationForWhomImage6: file(
        relativePath: { eq: "foundation-of-graph-design/web-designers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const image1 = data.foundationForWhomImage1.childImageSharp.fluid;
  const image2 = data.foundationForWhomImage2.childImageSharp.fluid;
  const image3 = data.foundationForWhomImage3.childImageSharp.fluid;
  const image4 = data.foundationForWhomImage4.childImageSharp.fluid;
  const image5 = data.foundationForWhomImage5.childImageSharp.fluid;
  const image6 = data.foundationForWhomImage6.childImageSharp.fluid;

  return (
    <Section>
      <Container>
        <Wrapper>
          <SC.Title>
            <ArtDirectionLogo />
            Для кого Арт-Директич<span>?</span>
          </SC.Title>
          <SC.InfoWrapper>
            <SC.ImagesWrapper>
              <SC.ItemImg fluid={image3} />
              <SC.ItemImg fluid={image2} />
              <SC.ItemImg fluid={image4} />
              <SC.ItemImg fluid={image1} />
              {!isMobile && (
                <>
                  <SC.ItemImg fluid={image5} />
                  <SC.ItemImg fluid={image6} />
                </>
              )}
            </SC.ImagesWrapper>
            <SC.Info>
              <SC.InfoItem>
                <SC.StyledFoundationLogo />
                <SC.InfoText>
                  Для тех, кто хочет получить от курса{" "}
                  <Link to="/foundation-of-graphic-design">
                    Фундамент Графдизайна 2.0
                  </Link>{" "}
                  намного больше. <SC.BreakLine />
                  <SC.GreyText>
                    Ваши домашние задания будет проверять автор курса, Вадим
                    Гранич, на индивидуальных сессиях по видеосвязи.
                  </SC.GreyText>
                </SC.InfoText>
              </SC.InfoItem>
              <SC.StyledInfoItem>
                <SC.StyledCopywritingLogo />
                <SC.InfoText>
                  Также в обучение входит изучение основ маркетинга, для этого
                  вы получите на 5 неделе мастер-класс Графдизайн Копирайтинга.
                </SC.InfoText>
              </SC.StyledInfoItem>
              <SC.StyledInfoItem>
                <SC.StyledSuitcaseIcon />
                <SC.InfoText>
                  Вдобавок к выше перечисленному, на сессиях Вадим также будет
                  разбирать ваши личные проекты, помогая их улучшить через
                  призму учебной теории.
                </SC.InfoText>
              </SC.StyledInfoItem>
            </SC.Info>
          </SC.InfoWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default ArtDirectionForWhom;
