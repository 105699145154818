// react/gatsby imports
import React from "react";
// assets
import ogImage from "assets/images/seo/art-directeach.jpg";
import ogImageVK from "assets/images/seo/vk/art-directeach.jpg";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import ArtDirectionOffer from "components/pages/art-directeach/offer";
import ArtDirectionForWhom from "components/pages/art-directeach/for-whom";
import ArtDirectionCurriculum from "components/pages/art-directeach/curriculum";
import ArtDirectionSupport from "components/pages/art-directeach/support";
import ArtDirectionVideoSession from "components/pages/art-directeach/video-session";
import ArtDirectionTeachingHow from "components/pages/art-directeach/teaching-how";

const description =
  "Индивидуальное прохождение курса Фундамент Графдизайна 2.0 с Вадимом Граничем + секретный мастер-класс Графдизайн Копирайтинга";

// 22.11.2023
// Страница была скрыта. Коммент от Вадима:
// И закрой пожалуйста страницу Арт-Директича. Я пока что останавливаю этот учебный продукт. Убери его ещё из учебной программы (с главной страницы сайта) и с формы оплаты в Фундаменте Графдизайна.
const ArtDirectionPage = () => (
  <Layout>
    <SEO
      title="Арт-Директич"
      description={description}
      socialDescription={description}
      keywords={["art-directeach", "гранич", "granich", "support"]}
      ogImage={ogImage}
      ogImageVk={ogImageVK}
      url={generateCanonicalLink(PAGE_SLUGS.artDirecteach)}
      noIndex
    />
    <div className="section-top-block" />
    <ArtDirectionOffer />
    <ArtDirectionForWhom />
    <ArtDirectionCurriculum />
    <ArtDirectionVideoSession />
    <ArtDirectionTeachingHow />
    <ArtDirectionSupport />
  </Layout>
);

export default ArtDirectionPage;
