import React from "react";
import { Container } from "components/style";
import IndividualSupportLogo from "assets/svgs/art-direction/art-direction_logo.svg";
import FoundationLogo from "assets/svgs/art-direction/foundation_logo.svg";
import * as SC from "./style";

const ArtDirectionOffer = () => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <SC.LogoWrapper>
          <IndividualSupportLogo />
          <FoundationLogo />
        </SC.LogoWrapper>
        <SC.Title>
          <span>Арт-Директич</span>
          <i>Индивидуальное обучение</i>
        </SC.Title>
        <SC.Descr>
          Индивидуальное обучение с Вадимом Граничем по фундаментальной теории
          графического дизайна с изучением основ маркетинга и разбором ваших
          личных проектов
        </SC.Descr>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default ArtDirectionOffer;
