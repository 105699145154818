import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TelegramIcon from "assets/svgs/social-side-icons/social-side-icon-telegram_noshadow.svg";
import { Container, Section } from "components/style";
import IndividualSupportLogo from "assets/svgs/art-direction/art-direction_logo.svg";
import InfoIcon from "assets/svgs/art-direction/info.svg";
import * as SC from "./style";

const ArtDirectionSupport = () => {
  const authorImage = useStaticQuery(graphql`
    query artDirectionindividualSupportAuthorImage {
      placeholderImage: file(relativePath: { eq: "author-zoomed.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <div>
          <SC.Wrapper>
            <SC.MainInfoWrapper>
              <SC.MainInfo>
                <SC.Title>
                  Запись на Арт-Директич <IndividualSupportLogo />
                </SC.Title>
                <SC.AuthorWrapper>
                  <SC.AuthorImage
                    fluid={authorImage.placeholderImage.childImageSharp.fluid}
                  />
                  <SC.TitleText>
                    Напишите Вадиму в личные сообщения в телеграме для уточнения
                    свободных мест на Арт-Директич
                  </SC.TitleText>
                </SC.AuthorWrapper>
                <SC.Form>
                  <SC.PaymentWrapper>
                    <SC.Button
                      target="_blank"
                      href="https://t.me/vadim_granich"
                    >
                      <TelegramIcon />
                      @vadim_granich
                    </SC.Button>
                    <SC.Price>50,000 ₽</SC.Price>
                  </SC.PaymentWrapper>
                </SC.Form>
              </SC.MainInfo>
            </SC.MainInfoWrapper>
          </SC.Wrapper>
          <SC.AdditionalInfo>
            <InfoIcon />
            <SC.InfoText>
              Дополнительно уточните по каким дням недели вам удобно проводить
              сессии разбора домашних заданий. И когда вы планируете начать
              обучение.
              <span />
              Предпочтительные дни проведения сессий — Пн, Вт или Ср. В первой
              половине дня.
            </SC.InfoText>
          </SC.AdditionalInfo>
          <SC.Wrapper>
            <SC.MainInfoWrapper>
              <SC.MainInfo>
                <SC.Title>
                  Разовая консультация <IndividualSupportLogo />
                </SC.Title>
                <SC.StyledTitleText>
                  В индивидуальном порядке около часа (иногда больше) с разбором
                  ваших проектов и определением дальнейших действий для развития
                  в графическом дизайне
                </SC.StyledTitleText>
                <SC.AuthorWrapper>
                  <SC.AuthorImage
                    fluid={authorImage.placeholderImage.childImageSharp.fluid}
                  />
                  <SC.SemiTransparentText>
                    Напишите Вадиму в личные сообщения в телеграме для уточнения
                    деталей
                  </SC.SemiTransparentText>
                </SC.AuthorWrapper>
                <SC.Form>
                  <SC.PaymentWrapper>
                    <SC.Button
                      target="_blank"
                      href="https://t.me/vadim_granich"
                    >
                      <TelegramIcon />
                      @vadim_granich
                    </SC.Button>
                    <SC.Price>5000 ₽</SC.Price>
                  </SC.PaymentWrapper>
                </SC.Form>
              </SC.MainInfo>
            </SC.MainInfoWrapper>
          </SC.Wrapper>
        </div>
      </Container>
    </Section>
  );
};

export default ArtDirectionSupport;
