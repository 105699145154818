import React from "react";
import { Container, Section, Wrapper } from "components/style";
import EmojiIcon from "assets/svgs/art-direction/emoji.svg";
import WarningIcon from "assets/svgs/art-direction/warning.svg";
import * as SC from "./style";
import {
  LessonNumber,
  LessonsWrapper,
  LessonContent,
  Lesson,
  Wrap,
  Title,
  StyledBookIcon,
} from "../curriculum/style";

const ArtDirectionTeachingHow = () => (
  <Section>
    <Container>
      <Wrapper style={{ position: "relative" }}>
        <Title>
          Как проходит созвон
          <SC.StyledPhoneIcon />
        </Title>
        <SC.StyledSubtitle>Созвон состоит из трёх частей</SC.StyledSubtitle>
        <LessonsWrapper style={{ marginBottom: "0" }}>
          <Lesson>
            <LessonNumber>1</LessonNumber>
            <LessonContent>
              <Wrap>
                <StyledBookIcon />
                40 минут проверяем ДЗ
              </Wrap>
            </LessonContent>
          </Lesson>
          <Lesson>
            <LessonNumber>2</LessonNumber>
            <LessonContent>
              <Wrap>
                <SC.StyledPalmIcon />
                15 минут перерыв
              </Wrap>
            </LessonContent>
          </Lesson>
          <Lesson>
            <LessonNumber>3</LessonNumber>
            <LessonContent>
              <Wrap>
                <StyledBookIcon />
                40 минут разбираем ваши проекты
              </Wrap>
            </LessonContent>
          </Lesson>
        </LessonsWrapper>
        <SC.AdditionalInfo>
          <SC.FirstRow>
            <WarningIcon />
          </SC.FirstRow>
          <SC.SecondRow>
            Да, этого времени точно хватает, чтобы провести качественную сессию
            созвона
            <EmojiIcon />
          </SC.SecondRow>
        </SC.AdditionalInfo>
      </Wrapper>
    </Container>
  </Section>
);

export default ArtDirectionTeachingHow;
