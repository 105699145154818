import React from "react";
import { Container, Section, Wrapper } from "components/style";
import * as SC from "./style";

const ArtDirectionVideoSession = () => (
  <Section>
    <Container>
      <Wrapper>
        <SC.Title>
          <span>Сессия созвона</span> — это проверка ДЗ
          <SC.StyledBookIcon /> (заданий и творческого макета){" "}
          <SC.StyledPlusIcon /> разбор ваших личных проектов
          <SC.StyledHeartIcon /> через призму теории курса
        </SC.Title>
        <SC.Description>
          Да, помимо прохождения Курса, Вадим ещё будет разбирать ваши личные
          проекты, подсказывая как их улучшить по теории Курса.
        </SC.Description>
      </Wrapper>
    </Container>
  </Section>
);

export default ArtDirectionVideoSession;
