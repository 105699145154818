import styled from "styled-components";
import BookIcon from "assets/svgs/art-direction/book.svg";
import PhoneIcon from "assets/svgs/art-direction/phone.svg";
import HatIcon from "assets/svgs/art-direction/hat.svg";
import CalendarIcon from "assets/svgs/art-direction/calendar.svg";
import XFiveIcon from "assets/svgs/art-direction/five-x.svg";
import PhoneCallIcon from "assets/svgs/art-direction/phone-call.svg";
import InfoIcon from "assets/svgs/art-direction/info.svg";
import CopyrightIcon from "assets/svgs/courses-order/copyrighting.svg";
import FoundationtIcon from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 6vw;
  line-height: 0.75;
  position: relative;
  margin-bottom: 2vw;
  margin-left: 0vw;
  white-space: nowrap;
  letter-spacing: -0.01vw;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    white-space: nowrap;
    font-size: 7.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 4vw;
    line-height: 0.9;
    font-weight: 600;
  }
`;

export const WeeksSubtitle = styled.span`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 4.5vw;
  color: var(--granich-light-grey);
  display: block;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    font-size: 7vw;
  }
`;

export const LessonsWrapper = styled.div`
  margin-bottom: 4vw;
`;

export const Lesson = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1vw;

  :last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 1vw;
    align-items: start;
  }
`;

export const LessonNumber = styled.div`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5vw;
  color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  width: 3vw;
  margin-right: 1.5vw;
  margin-top: -1.2vw;

  @media only screen and (max-width: 575px) {
    font-size: 7vw;
    margin-right: 3vw;
  }
`;

export const LessonContent = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    flex-wrap: wrap;
  }
`;

export const Wrap = styled.div`
  background: rgba(0, 0, 0, 0.07);
  border-radius: 0.6vw;
  padding: 0.8vw 1.3vw;
  font-size: 1.75vw;
  font-weight: 500;
  letter-spacing: -0.03vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.6vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;
    padding: 1vw 3vw;
    border-radius: 1vw;
    white-space: nowrap;
    margin-bottom: 2vw;

    svg {
      margin-right: 1vw;
    }
  }
`;

export const StyledBookIcon = styled(BookIcon)`
  width: 2vw;

  @media only screen and (max-width: 575px) {
    width: 3.5vw;
    min-width: 3.5vw;
  }
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  width: 1.8vw;
  @media only screen and (max-width: 575px) {
    width: 3.5vw;
    min-width: 3.5vw;
  }
`;

export const StyledHatIcon = styled(HatIcon)`
  width: 2vw;
  @media only screen and (max-width: 575px) {
    width: 3.5vw;
    min-width: 3.5vw;
  }
`;

export const InfoWrapper = styled.div``;

export const InfoItem = styled.div`
  margin-bottom: 1.5vw;
  display: flex;
  align-items: start;
  font-weight: 500;
  :last-child {
    margin-bottom: 0;
    div {
      color: var(--granich-light-grey);
    }
  }

  svg {
    margin-right: 1.3vw;
    width: 3vw;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;

    svg {
      width: 6vw;
      min-width: 6vw;
      transform: translateX(-1.5vw);
      margin-left: -1vw;
    }
  }
`;

export const InfoText = styled.div`
  line-height: 1.4;
  font-size: 1.45vw;
  letter-spacing: -0.01vw;
  font-weight: 500;

  @media only screen and (max-width: 575px) {
    font-size: 4vw;
  }
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  width: 3vw;
  min-width: 3.5vw;

  @media only screen and (max-width: 575px) {
    width: 8vw;
    min-width: 8vw;
  }
`;

export const StyledXFiveIcon = styled(XFiveIcon)`
  width: 3vw;
  min-width: 3.5vw;

  @media only screen and (max-width: 575px) {
    width: 8vw;
    min-width: 8vw;
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 3vw;
  min-width: 3.5vw;

  @media only screen and (max-width: 575px) {
    width: 8vw;
    min-width: 8vw;
  }
`;

export const StyledPhoneCallIcon = styled(PhoneCallIcon)`
  width: 3vw;
  min-width: 3.5vw;

  @media only screen and (max-width: 575px) {
    width: 8vw;
    min-width: 8vw;
  }
`;

export const StyledCopyrightIcon = styled(CopyrightIcon)`
  width: 1.6vw;
  min-width: 1.6vw;
  margin-right: 0.5vw;
  transform: scale(1.2);

  @media only screen and (max-width: 575px) {
    width: 2.5vw !important;
    min-width: 2.5vw !important;
  }
`;

export const StyledFoundationtIcon = styled(FoundationtIcon)`
  width: 1.6vw;
  min-width: 1.6vw;
  margin-right: 1vw !important;
  transform: scale(1.5);

  @media only screen and (max-width: 575px) {
    width: 2.5vw !important;
    min-width: 2.5vw !important;
    margin-right: 2vw !important;
    transform: scale(1.8);
  }
`;
