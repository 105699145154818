import styled from "styled-components";

export const Section = styled.section`
  margin-bottom: 2vw;
`;

export const Wrapper = styled.div`
  background: white;
  border-radius: 0.6vw;
  padding: 4vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 575px) {
    padding: 10vw 6vw;
    border-radius: 2.5vw;
  }
`;

export const Title = styled.h1`
  font-size: 6.9vw;
  font-weight: 500;
  font-family: EB Garamond;
  line-height: 0.85;
  margin: 0;
  margin-bottom: 2vw;
  text-align: center;
  span {
    letter-spacing: -0.2vw;
    font-family: Inter;
    font-weight: 800;
    font-size: 7.7vw;
    display: block;
  }
  i {
    font-family: Inter;
    font-size: 2.5vw;
    font-style: normal;
    color: var(--granich-red);
    border: 0.2vw solid var(--granich-red);
    border-radius: 0.5vw;
    padding: 0.5vw 0.6vw;
    /* letter-spacing: -0.1rem; */
    display: inline-block;
  }

  @media only screen and (max-width: 575px) {
    font-size: 14vw;

    margin-bottom: 6vw;
    span {
      font-size: 14vw;
      letter-spacing: -0.5vw;
      margin-bottom: 2vw;
    }
    i {
      font-size: 5vw;
      border: 0.4vw solid var(--granich-red);
      border-radius: 1.5vw;
      padding: 1vw 1.5vw;
    }
  }
`;

export const Descr = styled.div`
  text-align: center;
  line-height: 1.5;
  font-size: 2vw;
  max-width: 49vw;
  span,
  a,
  a:hover {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 0.6vw;
    padding: 0.15vw 0.6vw;
  }
  @media only screen and (max-width: 575px) {
    line-height: 1.65;
    font-size: 4vw;
    max-width: 100%;
    span {
      font-size: 4vw;
      padding: 0.6vw 1.2vw;
      border-radius: 1vw;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  margin-bottom: 2vw;
  svg {
    width: 7vw;
  }
  svg {
    :first-child {
      width: 5vw;
      margin-right: 2vw;
    }
  }
  @media only screen and (max-width: 575px) {
    margin-bottom: 6vw;
    svg {
      width: 17vw;
      height: 18vw;
    }
    svg {
      :first-child {
        width: 12vw;
        margin-right: 4vw;
      }
    }
  }
`;
