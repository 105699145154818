import React from "react";
import { Container, Section, Wrapper } from "components/style";
import * as SC from "./style";

const ArtDirectionCurriculum = () => (
  <Section>
    <Container>
      <Wrapper>
        <SC.Title>График уроков и созвонов</SC.Title>
        <SC.WeeksSubtitle>Недели</SC.WeeksSubtitle>
        <SC.LessonsWrapper>
          <SC.Lesson>
            <SC.LessonNumber>1</SC.LessonNumber>
            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledFoundationtIcon />
                <SC.StyledBookIcon />1 ДЗ
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
          <SC.Lesson>
            <SC.LessonNumber>2</SC.LessonNumber>
            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledFoundationtIcon />
                <SC.StyledBookIcon />2 ДЗ
              </SC.Wrap>
              <SC.Wrap>
                <SC.StyledPhoneIcon /> Созвон № 1
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
          <SC.Lesson>
            <SC.LessonNumber>3</SC.LessonNumber>

            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledFoundationtIcon />
                <SC.StyledBookIcon />3 ДЗ
              </SC.Wrap>

              <SC.Wrap>
                <SC.StyledPhoneIcon />
                Созвон №2
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
          <SC.Lesson>
            <SC.LessonNumber>4</SC.LessonNumber>
            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledFoundationtIcon />
                <SC.StyledBookIcon />4 ДЗ
              </SC.Wrap>
              <SC.Wrap>
                <SC.StyledPhoneIcon /> Созвон №3
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
          <SC.Lesson>
            <SC.LessonNumber>5</SC.LessonNumber>
            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledCopyrightIcon /> Графдизайн Копирайтинга (
                <SC.StyledBookIcon style={{ marginLeft: "0.3vw" }} /> 5 ДЗ){" "}
              </SC.Wrap>
              <SC.Wrap>
                <SC.StyledPhoneIcon /> Созвон №4
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
          <SC.Lesson>
            <SC.LessonNumber>6</SC.LessonNumber>
            <SC.LessonContent>
              <SC.Wrap>
                <SC.StyledPhoneIcon /> Созвон №5
              </SC.Wrap>
              <SC.Wrap>
                <SC.StyledHatIcon /> Подведение итогов
              </SC.Wrap>
            </SC.LessonContent>
          </SC.Lesson>
        </SC.LessonsWrapper>
        <SC.InfoWrapper>
          <SC.InfoItem>
            <SC.StyledCalendarIcon />
            <SC.InfoText>
              Арт-Директич длится 6 недель. 1‑4 недели соответствуют 1‑4 урокам
              Фундамента Графдизайна 2.0. На 5 неделе вы изучаете мастер-класс
              Графдизайн Копирайтинга. Он поможет вам лучше понимать дизайн
              с точки зрения маркетинга. Параллельно мы разбираем ваши текущие
              проекты (или помогаю собрать с нуля).
            </SC.InfoText>
          </SC.InfoItem>
          <SC.InfoItem>
            <SC.StyledXFiveIcon />
            <SC.InfoText>
              Каждую неделю вы получаете домашнее задание. Вы можете делать
              только те задания, которые считаете важными, сессии созвона
              проводим в любом случае. Всего вы сможете создать 5 творческих
              макетов (коммерческих плакатов) для своего портфолио.
              И, параллельно с этим, улучшить свои личные проекты через призму
              теории курса.
            </SC.InfoText>
          </SC.InfoItem>
          <SC.InfoItem>
            <SC.StyledPhoneCallIcon />
            <SC.InfoText>
              В течение Арт‑Директича мы фиксируем один конкретный день, когда
              будем созваниваться каждую неделю. На созвоне проводим разбор
              вашего домашнего задания, и личных проектов. Одна сессия созвона
              состоит из двух частей по 40 минут в зуме, с перерывом 15 минут
              между ними. Докрутки ДЗ и своих проектов сможете досылать мне
              в личку в телеграме в течение всего времени Арт‑Директича.
            </SC.InfoText>
          </SC.InfoItem>
          <SC.InfoItem>
            <SC.StyledInfoIcon />
            <SC.InfoText>
              P.S. Вы не привязаны к конкретному потоку
              Фундамента Графдизайна 2.0. Можете начать обучение когда
              вам удобно.
            </SC.InfoText>
          </SC.InfoItem>
        </SC.InfoWrapper>
      </Wrapper>
    </Container>
  </Section>
);

export default ArtDirectionCurriculum;
