import styled from "styled-components";
import PalmIcon from "assets/svgs/art-direction/palm.svg";
import PhoneIcon from "assets/svgs/art-direction/phone_black.svg";
import { WeeksSubtitle } from "../curriculum/style";

export const StyledPalmIcon = styled(PalmIcon)`
  width: 2vw;

  @media only screen and (max-width: 575px) {
    width: 3.5vw;
    min-width: 3.5vw;
  }
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  margin-left: 1vw;
  width: 4.5vw;
  min-width: 4.5vw;

  div {
    max-width: 100% !important;
  }
`;

export const AdditionalInfo = styled.div`
  bottom: 9vw;
  right: 9vw;
  position: absolute;
  max-width: 21vw;
  background: linear-gradient(
    160deg,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.2) 100%
  );

  border-radius: 0.8vw;
  padding: 1vw;
  display: flex;

  align-items: start;
  transform: rotate(5deg);
  svg {
    width: 1.5vw;
    min-width: 1.5vw;

    :first-child {
      margin-right: 0.7vw;
    }

    :nth-child(2) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 4vw;
    transform: rotate(-1deg);
    max-width: 95vw;
    padding: 2vw;
    border-radius: 2vw;

    svg {
      width: 3.5vw;
      min-width: 3.5vw;

      :first-child {
        margin-right: 1.5vw;
      }
    }
  }
`;

export const FirstRow = styled.div`
  svg {
    position: relative;
    top: 0.2vw;
  }
`;

export const SecondRow = styled.div`
  font-weight: 500;
  line-height: 1.4;
  font-size: 1.35vw;
  letter-spacing: -0.01vw;
  svg {
    position: relative;
    top: 0.2vw;
    width: 1vw;
    min-width: 1vw;
    margin-left: 0.7vw;
    transform: scale(1.5);
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.1vw;

    svg {
      width: 2.5vw;
      margin-left: 2vw;
    }
  }
`;

export const StyledSubtitle = styled(WeeksSubtitle)`
  @media only screen and (max-width: 575px) {
    font-size: 5.7vw;
    margin-top: -2vw;
    margin-bottom: 4vw;
  }
`;
