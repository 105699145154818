import styled from "styled-components";
import HeartIcon from "assets/svgs/art-direction/video-session/heart.svg";
import BookIcon from "assets/svgs/art-direction/video-session/book.svg";
import PlusIcon from "assets/svgs/art-direction/video-session/plus.svg";

export const Title = styled.div`
  font-size: 5vw;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 3vw;
  max-width: 98%;

  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-weight: 500;
  }

  svg {
    width: 4.5vw;
    min-width: 4.5vw;
    margin-left: 2vw;
    position: relative;
    top: 1vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 7.6vw;
    max-width: 100%;

    svg {
      width: 7vw;
      min-width: 7vw;
    }
  }
`;

export const Description = styled.div`
  font-size: 1.9vw;
  line-height: 1.5;
  font-weight: 500;

  @media only screen and (max-width: 575px) {
    font-size: 3.3vw;
  }
`;

export const StyledHeartIcon = styled(HeartIcon)``;

export const StyledBookIcon = styled(BookIcon)`
  top: 0.5vw !important;
`;

export const StyledPlusIcon = styled(PlusIcon)`
  margin-left: 0 !important;
  top: 2vw !important;
  transform: scale(0.9);

  @media only screen and (max-width: 575px) {
    top: 1.5vw !important;
  }
`;
