import styled from "styled-components";
import Img from "gatsby-image";
import FoundationLogo from "assets/svgs/art-direction/foundation_logo.svg";
import FullscreenIcon from "assets/svgs/art-direction/scale.svg";
import CopywritingLogo from "assets/svgs/courses-order/copyrighting.svg";
import SuitcaseIcon from "assets/svgs/art-direction/suitcase.svg";

export const Title = styled.h2`
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  font-size: 5.4vw;
  line-height: 0.75;
  position: relative;
  margin-bottom: 5vw;
  margin-left: 0vw;
  white-space: nowrap;
  letter-spacing: -0.1vw;
  display: flex;
  align-items: center;
  span {
    color: var(--granich-red);
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
    font-size: 5.4vw;
  }
  svg {
    width: 5vw;
    margin-right: 2vw;
    height: 7vw;
    top: 1.3vw;
    position: relative;
  }
  @media only screen and (max-width: 575px) {
    white-space: nowrap;
    font-size: 6.5vw;
    margin-left: 0;
    letter-spacing: -0.1vw;
    margin-bottom: 5vw;
    line-height: 0.9;
    font-weight: 600;

    svg {
      width: 10vw;
      min-width: 10vw;
      height: 10vw;
      margin-left: -3vw;
      margin-right: 0.3vw;
    }

    span {
      font-size: 9.5vw;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: start;
  font-size: 1.5vw;
  margin-bottom: 3vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 8vw;
    flex-direction: column;
  }
`;

export const ItemImages = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5vw !important;
  min-width: 37vw;
  width: 37vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;
  }
`;

export const ItemImg = styled(Img)`
  width: 13vw;
  height: 13vw;
  min-width: 13vw;
  min-height: 13vw;
  margin-right: -1vw;
  @media only screen and (max-width: 575px) {
    width: 21vw;
    height: 21vw;
    min-width: 21vw;
    min-height: 21vw;
    margin-right: 0;
    margin-left: -0.5vw;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const ItemText = styled.div`
  font-size: 1.5vw;
  line-height: 1.4;
  b {
    display: block;
    font-size: 2vw;
    color: var(--granich-black);
  }
  @media only screen and (max-width: 575px) {
    font-size: 2.75vw;
    margin-top: -2vw;

    b {
      font-size: 3.75vw;
    }
  }
`;
export const InfoWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 575px) {
    display: block;
  }
`;

export const StyledFoundationLogo = styled(FoundationLogo)``;

export const StyledCopywritingLogo = styled(CopywritingLogo)`
  transform: translate(-0.6vw, -0.5vw);
  top: 0.4vw;
  position: relative;

  @media only screen and (max-width: 575px) {
    transform: translate(-2vw, -0.5vw);
  }
`;

export const StyledSuitcaseIcon = styled(SuitcaseIcon)`
  transform: scale(0.9) translate(-0.6vw, -0.5vw);

  @media only screen and (max-width: 575px) {
    transform: scale(0.9) translate(-2vw, -0.5vw);
  }
`;

export const StyledFullscreenIcon = styled(FullscreenIcon)`
  transform: scale(0.6) translate(-0.6vw, -0.5vw);

  @media only screen and (max-width: 575px) {
    transform: scale(0.6) translate(-2vw, -0.5vw);
  }
`;

export const InfoText = styled.div`
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.03vw;
  font-size: 1.5vw;
  span,
  a {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 0.6vw;
    padding: 0.15vw 0.6vw;
    font-weight: 500;
    letter-spacing: -0.03vw;
  }
  br {
    margin-bottom: 1vw;
    display: block;
  }
  i {
    font-size: 1.5vw;
    letter-spacing: -0.03vw;
  }
  @media only screen and (max-width: 575px) {
    font-size: 4vw;
    span,
    a {
      font-size: 4vw;
      padding: 0.6vw 1.2vw;
      border-radius: 1vw;
    }
    i {
      font-size: 4vw;
      letter-spacing: -0.03vw;
    }
  }
`;

export const GreyText = styled.div`
  color: var(--granich-light-grey);
  font-weight: 500;
`;

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 36%;
  margin-left: -2vw;

  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 100%;
    margin-bottom: 3vw;
  }
`;

export const Info = styled.div`
  margin-left: 2vw;
  padding-top: 2vw;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-align: flex-start;
  margin-bottom: 2vw;
  svg {
    margin-right: 1vw;
    width: 4.5vw;
    min-width: 4.5vw;
    height: 4.5vw;
  }

  @media only screen and (max-width: 575px) {
    svg {
      margin-right: 2vw;
      width: 10vw;
      min-width: 10vw;
      height: 10vw;
    }
  }
`;

export const StyledInfoItem = styled(InfoItem)`
  max-width: 82%;
  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const BreakLine = styled.div`
  margin-right: 1vw;
  height: 1vw;
`;
