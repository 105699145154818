import styled from "styled-components";
import Img from "gatsby-image";

export const Wrapper = styled.div`
  background: var(--granich-dark-red);
  margin: 0 auto;
  border-radius: 0.6vw;
  padding: 1.5vw 4.5vw 3vw;
  display: flex;

  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
    padding: 2.5vw 4vw;
    flex-direction: column;
    align-items: end;
  }
`;

export const MainInfoWrapper = styled.div`
  display: flex;
`;

export const AuthorImage = styled(Img)`
  min-width: 6vw;
  min-height: 6vw;
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1.5vw;
  border: 2px solid white;

  @media only screen and (max-width: 575px) {
    width: 12vw;
    height: 12vw;
    min-width: 12vw;
    min-height: 12vw;
    margin-right: 3vw;
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
`;

export const MainInfo = styled.div`
  min-width: 18vw;
  margin-top: 0.3vw;

  @media only screen and (max-width: 575px) {
    min-width: auto;
  }
`;

export const SemiTransparentText = styled.div`
  color: rgba(256, 256, 256, 0.5);
  font-size: 1.5vw;
  max-width: 50%;
  line-height: 1.4;

  @media only screen and (max-width: 575px) {
    font-size: 2.9vw;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  color: white;
  font-family: EB Garamond;
  font-size: 5.4vw;
  font-style: italic;
  font-weight: 600;

  svg {
    width: 4vw;
    fill: white !important;
    margin-left: 1vw;
    position: relative;
    top: 0.5vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 6.7vw;
    white-space: nowrap;

    svg {
      width: 5vw;
    }
  }
`;

export const TitleText = styled.div`
  color: white;
  font-size: 1.8vw;
  line-height: 1.3;
  margin-bottom: 0.2vw;
  font-style: normal;

  b {
    color: white;
    display: block;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.2vw;
    margin-bottom: 2vw;
    top: 1vw;
    position: relative;

    b {
      font-size: 3.1vw;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  margin-left: -0.2vw;

  @media only screen and (max-width: 575px) {
    display: block;
    margin-top: 2vw;
  }
`;

export const Price = styled.div`
  color: white;
  font-family: EB Garamond;
  font-size: 4.5vw;
  font-style: italic;
  font-weight: 500;
  margin-right: 1.5vw;
  white-space: nowrap;

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    margin-right: 4vw;
  }
`;

export const Button = styled.a`
  background: linear-gradient(
    180deg,
    #444444 0%,
    #333333 20%,
    #1b1b1b 70%,
    rgba(1, 1, 1) 100%
  );
  color: white;
  padding: 1.5vw;
  text-align: center;
  border-radius: 0.6vw;
  font-size: 2.2vw;
  transition: transform 0.3s ease;
  box-shadow: 0.4vw 0.4vw 0.6vw rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  margin-right: 2vw;

  :hover {
    color: white;
    font-size: 2.2vw;
  }

  svg {
    width: 2.5vw;
    height: 2.5vw;
    transition: all 0.3s ease;
    transform: scale(1.35);
    margin-right: 1.2vw;
  }

  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  @media only screen and (max-width: 575px) {
    font-size: 3vw;
    padding: 2.5vw;
    border-radius: 1.5vw;
    box-shadow: 0.5vw 0.5vw 1vw rgba(0, 0, 0, 0.25);
    background: linear-gradient(
      180deg,
      #444444 0%,
      #333333 20%,
      #1b1b1b 70%,
      rgba(1, 1, 1) 100%
    );

    span {
      font-size: 2.5vw;
    }

    svg {
      width: 4vw;
      height: 4vw;
      transform: scale(1.3);
      margin-right: 1vw;
    }

    :hover {
      transform: none;
      font-size: 2.5vw;
    }
  }
`;

export const TooltipContent = styled.div`
  max-width: 20vw;
  color: var(--granich-grey);
  line-height: 1.3;
  text-align: left;
  padding: 0.2vw;

  span {
    display: block;
    margin: 0.5vw 0;
    color: var(--granich-grey);
    position: relative;
    padding-left: 0.8vw;

    :after {
      content: "";
      position: absolute;
      top: 0.4vw;
      left: 0;
      width: 0.25vw;
      height: 0.25vw;
      background: var(--granich-grey);
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 1vw;
    font-size: 2.75vw;
    max-width: 60vw;

    span {
      padding-left: 2vw;
      margin: 1vw 0;

      :after {
        content: "";
        position: absolute;
        top: 1.4vw;
        left: 0;
        width: 0.7vw;
        height: 0.7vw;
        background: var(--granich-grey);
        border-radius: 50%;
      }
    }
  }
`;

export const StyledTitleText = styled(TitleText)`
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 4vw;
  }
`;

export const BitCoinPayment = styled.div`
  display: flex;
  align-items: center;
  width: 96%;
  background: #b3041a;
  margin: 0 auto;
  border-radius: 0 0 0.6vw 0.6vw;
  padding: 1.1vw 2vw;
  color: white;
  font-weight: 500;
  font-size: 1.17vw;

  svg {
    width: 3vw;
    min-width: 3vw;
    margin-right: 0.7vw;

    :last-child {
      margin-right: 1vw;
    }
  }

  @media only screen and (max-width: 575px) {
    border-radius: 0 0 2.5vw 2.5vw;
    padding: 4.5vw 4vw 5vw;
    font-size: 2.7vw;
    align-items: end;

    svg {
      width: 7vw;
      min-width: 7vw;
      margin-right: 2vw;

      :last-child {
        margin-right: 2vw;
      }
    }
  }
`;

export const Arrow = styled.div`
  color: white;
  font-size: 6vw;
  margin: 0 1vw;

  @media only screen and (max-width: 575px) {
    font-size: 6vw;
    margin: 0 2vw;
  }
`;

export const PaymentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AdditionalInfo = styled.div`
  width: 90%;
  margin: auto;
  background: var(--granich-black);
  border-radius: 0 0 0.6vw 0.6vw;
  padding: 2vw;
  display: flex;
  margin-bottom: 3vw;

  svg {
    fill: rgba(256, 256, 256, 0.2);
    width: 3vw;
    height: 3vw;
    top: -0.3vw;
    position: relative;
    max-width: 3vw;
    margin-right: 1vw;
  }

  @media only screen and (max-width: 575px) {
    border-radius: 0 0 2.5vw 2.5vw;
    margin-bottom: 6vw;

    svg {
      width: 4vw;
      height: 4vw;
      min-width: 5vw;
      margin-right: 1.5vw;
      top: 0.3vw;
    }
  }
`;

export const InfoText = styled.div`
  color: rgba(256, 256, 256, 0.5);
  font-size: 1.35vw;
  line-height: 1.4;

  span {
    height: 1vw;
    display: block;
  }

  @media only screen and (max-width: 575px) {
    font-size: 2.9vw;
  }
`;
